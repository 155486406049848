import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CSVReader from 'react-csv-reader';
import Mutation, { MutationFn } from 'react-apollo/Mutation';
import { ApolloError } from 'apollo-boost';
import Ajv, { ErrorObject } from 'ajv';
import * as localize from 'ajv-i18n';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import uniqid from 'uniqid';
import { CREATE_STUDY_ENTRY } from '../graphql/StudyEntry';
import { SubmitButton } from './common/SubmitButton';
import { Status } from './common/StatusIndicator';
import { withApollo } from '../utils';
import { SchemaQuery, SchemaQueryContext } from './common/SchemaQuery';
import config from '../config';

export interface ICSVImporterProps extends WithTranslation {}

interface ICSVImporterState {
  data: Array<object>;
  csvErrors: Array<string>;
}

interface StringKeyedObject {
  [s: string]: any;
}

/**
 * Imports a CSV file containing study entries
 */
export class CSVImporterUnwrapped extends React.Component<ICSVImporterProps, ICSVImporterState> {
  constructor(props: ICSVImporterProps) {
    super(props);
    this.state = {
      data: [],
      csvErrors: [],
    };
  }

  handleFileLoadError = (csvErrors: Array<string>) => this.setState({ data: [], csvErrors });

  handleFileLoaded = (data: Array<object>) => this.setState({ data, csvErrors: [] });

  getStatus = (loading: boolean, error: ApolloError | undefined, data: object) => {
    if (loading) {
      return Status.Loading;
    }

    if (error) {
      return Status.ValidationFailed;
    }

    if (data) {
      return Status.Saved;
    }

    return Status.UnsavedChanges;
  };

  removeNullOrEmptyKeys = (obj: StringKeyedObject): StringKeyedObject => {
    let objCopy = cloneDeep(obj);
    Object.keys(objCopy).forEach(
      key => (objCopy[key] === null || objCopy[key] === '') && delete objCopy[key]
    );
    return objCopy;
  };

  submitRow = (mutateFunction: MutationFn, rowData: StringKeyedObject) => {
    let payload: StringKeyedObject = {};

    for (let property in rowData) {
      if (rowData.hasOwnProperty(property)) {
        set(payload, property, rowData[property]);
      }
    }

    mutateFunction({
      variables: {
        data: {
          data: JSON.stringify(payload),
        },
      },
    });
  };

  parseServerSideErrors = (error: ApolloError | undefined) => {
    let result = {
      propertyErrors: {},
      otherError: '',
    };

    if (!error || !error.graphQLErrors.length || !error.graphQLErrors[0].message) {
      return result;
    }

    let errorObj: StringKeyedObject = {};

    try {
      errorObj = JSON.parse(error.graphQLErrors[0].message);
    } catch (e) {
      result.otherError = error.graphQLErrors[0].message;
      return result;
    }

    result.propertyErrors = errorObj['data'] ? JSON.parse(errorObj.data) : {};

    return result;
  };

  parseClientSideErrors = (errors: ErrorObject[] | null | undefined) => {
    if (!errors) {
      return {};
    }

    localize[config.locale](errors);

    let clientSideErrors: StringKeyedObject = {};
    errors.map(
      (e: StringKeyedObject) => (clientSideErrors[e['dataPath'].substr(1)] = e['message'])
    );

    return clientSideErrors;
  };

  renderTableHeader = (keys: Array<string>) => (
    <thead>
      <tr>
        <th />
        <th />
        {keys.map((key: string, keyIdx: number) => (
          <th key={key}>{key}</th>
        ))}
      </tr>
    </thead>
  );

  renderButtonCell = (
    mutateFunction: MutationFn,
    data: StringKeyedObject,
    disabled: boolean,
    status: Status
  ) => (
    <td
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      <SubmitButton
        onClick={() => this.submitRow(mutateFunction, data)}
        showStatusIndicator={true}
        disabled={disabled}
        status={status}
      />
    </td>
  );

  renderErrorCell = (
    keys: Array<string>,
    clientSideErrors: StringKeyedObject,
    serverSideErrors: StringKeyedObject,
    otherError: string | undefined
  ) => {
    let errorObj: StringKeyedObject = [];

    const filterErrors = (errors: StringKeyedObject) => {
      for (let key in errors) {
        if (!keys.includes(key)) {
          errorObj[key] = errors[key];
        }
      }
    };

    filterErrors(serverSideErrors);
    filterErrors(clientSideErrors); // client side AJV errors override server side errors

    if (!errorObj) {
      return <td />;
    }

    return (
      <td className="align-middle">
        <ul>
          {Object.keys(errorObj).map((key: string, idx: number) => (
            <li key={uniqid()}>
              <strong>{key}</strong>
              {': '}
              {errorObj[key]}
            </li>
          ))}
          {otherError && <li>{otherError}</li>}
        </ul>
      </td>
    );
  };

  renderDataCells = (
    keys: Array<string>,
    data: StringKeyedObject,
    clientSideErrors: StringKeyedObject,
    serverSideErrors: StringKeyedObject
  ) =>
    keys.map((key: string, colIdx: number) => {
      const cellStyle = clientSideErrors[key] || serverSideErrors[key] ? 'table-danger' : '';

      const emptyCell = data[key] === '';

      return (
        <td
          key={uniqid()}
          className={`align-middle ${cellStyle}`}
          title={clientSideErrors[key] || serverSideErrors[key]}
        >
          <p className={`${emptyCell && 'text-center'} mb-0`}>
            {(clientSideErrors[key] || serverSideErrors[key]) && (
              <i className="fas fa-exclamation-triangle" />
            )}{' '}
            {emptyCell ? '—' : data[key]}
          </p>
        </td>
      );
    });

  renderData = (schema: object) => {
    const { data } = this.state;
    if (data.length === 0) {
      return <p className="font-italic text-center">{this.props.t('No data loaded')}</p>;
    }

    const keys = Object.keys(data[0]);
    const ajv = new Ajv({
      allErrors: true,
      coerceTypes: true,
      errorDataPath: 'property',
    });
    let validate = ajv.compile(schema);

    return (
      <div className="container-fluid table-responsive">
        <table className="table table-striped">
          {this.renderTableHeader(keys)}
          <tbody>
            {data.map((row: StringKeyedObject, rowIdx: number) => (
              <Mutation key={uniqid()} mutation={CREATE_STUDY_ENTRY}>
                {(mutateFunction, { loading, error, data: mutationData }) => {
                  let rowWithoutNulls = this.removeNullOrEmptyKeys(row);
                  const serverSideErrors: StringKeyedObject = this.parseServerSideErrors(error);

                  let clientSideErrors: StringKeyedObject = {};

                  if (!validate(rowWithoutNulls)) {
                    clientSideErrors = this.parseClientSideErrors(validate.errors);
                  }

                  return (
                    <tr>
                      {this.renderButtonCell(
                        mutateFunction,
                        rowWithoutNulls,
                        loading || mutationData,
                        this.getStatus(loading, error, mutationData)
                      )}
                      {this.renderErrorCell(
                        keys,
                        clientSideErrors,
                        serverSideErrors.propertyErrors,
                        serverSideErrors.otherError
                      )}
                      {this.renderDataCells(
                        keys,
                        row,
                        clientSideErrors,
                        serverSideErrors.propertyErrors
                      )}
                    </tr>
                  );
                }}
              </Mutation>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render = () => (
    <SchemaQuery>
      <SchemaQueryContext.Consumer>
        {({ schema }) => (
          <React.Fragment>
            <div className="container">
              <CSVReader
                label={this.props.t('Select a CSV file to import')}
                cssInputClass="form-control-file"
                cssClass="form-group"
                onFileLoaded={this.handleFileLoaded}
                onError={this.handleFileLoadError}
                parserOptions={{
                  skipEmptyLines: true,
                  header: true,
                }}
              />
            </div>
            {this.renderData(schema)}
            {this.state.csvErrors && this.state.csvErrors}
          </React.Fragment>
        )}
      </SchemaQueryContext.Consumer>
    </SchemaQuery>
  );
}

export const CSVImporter = withApollo(withTranslation()(CSVImporterUnwrapped));

import React from "react";

import { rangeSpec } from "react-jsonschema-form-bs4/lib/utils";

export interface AnnotatedUpDownWidgetOptions {
  prependText?: string;
  appendText?: string;
}

export interface AnnotatedUpDownWidgetProps {
  options: AnnotatedUpDownWidgetOptions;
  // Catch-all for RJSF props (TODO need to fix typings)
  [x: string]: any;
}

export const AnnotatedUpDownWidget: React.FunctionComponent<AnnotatedUpDownWidgetProps> = (
  props
) => {
  const {
    registry: {
      widgets: { BaseInput },
    },
    options,
  } = props;

  return (
    <div className="input-group">
      {options.prependText && (
        <div className="input-group-prepend">
          <span className="input-group-text">{options.prependText}</span>
        </div>
      )}
      <BaseInput type="number" {...props} {...rangeSpec(props.schema)} />
      {options.appendText && (
        <div className="input-group-append">
          <span className="input-group-text">{options.appendText}</span>
        </div>
      )}
    </div>
  );
};

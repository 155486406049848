import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloProvider } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import * as Cookies from 'js-cookie';
import * as React from 'react';

const httpLink = createHttpLink({
  uri: '/graphql/',
  credentials: 'include',
});

const csrfLink = setContext((_, { headers }) => {
  const csrfToken = Cookies.get('csrftoken');
  return {
    headers: {
      ...headers,
      'X-CSRFToken': csrfToken || '',
    },
  };
});

const client = new ApolloClient({
  link: csrfLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function withApollo(Component: any) {
  class WithApollo extends React.Component {
    public render() {
      return (
        <ApolloProvider client={client}>
          <Component {...this.props} />
        </ApolloProvider>
      );
    }
  }
  return WithApollo;
}

export { withApollo };

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import deTranslation from './locales/de/translation.json';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import config from './config';

// Moment + react-widgets
// Need to explicitly import required locales (all others will be stripped)
import 'moment/locale/de';

Moment.locale(config.locale);
momentLocalizer();

// i18next
const resources = {
  de: {
    translation: deTranslation,
  },
};

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: config.locale == 'en' ? undefined : config.locale,
    fallbackLng: false,
    nsSeparator: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

import * as React from 'react';
import Query from 'react-apollo/Query';
import { useTranslation } from 'react-i18next';
import { GET_SCHEMA } from '../../graphql/StudyEntry';
import LoadingSpinner from './LoadingSpinner';

interface ISchemaQueryProps {
  /**
   * This component's children. See `SchemaQueryContext` to see the provided context.
   */
  children: React.ReactNode;
}

/**
 * Context for the Schema query, provides an object with `schema` and `uiSchema`
 * properties
 */
const SchemaQueryContext = React.createContext({ schema: {}, uiSchema: {} });

/**
 * Retrieves the current JSON and UI Schema and provides it via a React context
 */
const SchemaQuery = (props: ISchemaQueryProps) => {
  const [t] = useTranslation();

  return (
    <Query query={GET_SCHEMA} fetchPolicy={'cache-and-network'}>
      {({ loading: schemaLoading, error: schemaError, data: schemaData }) => {
        if (schemaLoading) {
          return <LoadingSpinner />;
        }

        if (schemaError) {
          return (
            <div className="alert alert-danger" role="alert">
              {t("Error: Couldn't retrieve schema")}
            </div>
          );
        }

        const schema = JSON.parse(schemaData.schema.schema);
        const uiSchema = JSON.parse(schemaData.schema.uiSchema);
        return (
          <SchemaQueryContext.Provider value={{ schema, uiSchema }}>
            {props.children}
          </SchemaQueryContext.Provider>
        );
      }}
    </Query>
  );
};

export { SchemaQuery, SchemaQueryContext };

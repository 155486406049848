import * as React from 'react';

/**
 * Displays a (centered) loading spinner
 */
const LoadingSpinner = () => {
  return (
    <div className="d-flex justify-content-center">
      <p>
        <span className="fa fa-spinner fa-pulse" />
      </p>
    </div>
  );
};

export default LoadingSpinner;

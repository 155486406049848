import * as React from 'react';
import { useTranslation } from 'react-i18next';

export enum Status {
  Saved,
  Loading,
  UnsavedChanges,
  ValidationFailed,
}

export interface IStatusIndicatorProps {
  /**
   * Status to be displayed
   **/
  status: Status;
}

/**
 * Displays current form status with an icon and text. See `Status`.
 */
export const StatusIndicator = (props: IStatusIndicatorProps) => {
  const [t] = useTranslation();

  let icon, message;

  switch (props.status) {
    case Status.Loading: {
      icon = 'fa fa-spinner fa-pulse fa-fw';
      message = t('Saving');
      break;
    }
    case Status.Saved: {
      icon = 'fas fa-check fa-fw';
      message = t('Saved!');
      break;
    }
    case Status.ValidationFailed: {
      icon = 'fas fa-exclamation-triangle fa-fw';
      message = t('Validation failed!');
      break;
    }
    case Status.UnsavedChanges: {
      icon = 'fas fa-edit fa-fw';
      message = t('Unsaved');
      break;
    }
    default:
      return <React.Fragment />;
  }

  return (
    <div>
      <span className={icon} />
      <span className="ml-1">{message}</span>
    </div>
  );
};

// This is a hack! AJV is not exposed via RJSF and the error list provided to
// transformErrors is in a RJSF specific format, so, in order to use ajv-i18n
// we have to:
// 1. transform the error objects back into AJV format
// 2. run ajv-i18n
// 3. transform the error objects once more into RJSF format
// There is one limitation to ajv-i18n: it does not support the (deprecated)
// `errorDataPath: 'property'` AJV option that RJSF depends on. This option
// formats messages like "is a required property" rather than "should have
// required property firstName". As such, transformErrors() formats messages
// in both EN and DE into the latter format for consistency.

import * as localize from 'ajv-i18n';
import config from '../../../config';

/**
 * Transforms AJV errors into RJSF errors. Stolen from RJSF.
 */
function transformAjvErrors(errors = []) {
  if (errors === null) {
    return [];
  }

  return errors.map(e => {
    const { dataPath, keyword, message, params } = e;
    let property = `${dataPath}`;

    // put data in expected format
    return {
      name: keyword,
      property,
      message,
      params, // specific to ajv
      stack: `${property}: ${message}`.trim(),
    };
  });
}

/**
 * Transforms RJSF errors back into AJV errors. Needed so we can run ajv-i18n,
 * which expects errors in AJV format.
 */
const rjsfToAjvErrors = errors => {
  if (errors === null) {
    return [];
  }

  return errors.map(e => {
    const { property, name, message, params, stack } = e;

    return {
      name,
      keyword: name,
      property,
      dataPath: property,
      message,
      params,
      stack,
    };
  });
};

/**
 * Localizes RJSF errors, meant to be passed to a RJSF `Form` as the `transformErrors` prop.
 * @todo hack!
 */
const localizeErrors = errors => {
  const ajvErrors = rjsfToAjvErrors(errors);
  localize[config.locale](ajvErrors);
  return transformAjvErrors(ajvErrors);
};

export default localizeErrors;

import * as React from 'react';
import Mutation from 'react-apollo/Mutation';
import { UPDATE_STUDY_ENTRY, CREATE_STUDY_ENTRY } from '../../graphql/StudyEntry';

interface IStudyEntryMutationProps {
  /**
   * Whether this mutation will update or create a StudyEntry
   * @default false
   */
  isExistingStudyEntry: boolean;
  /**
   * This component's children. See `StudyEntryMutationContext` to see the provided context.
   */
  children: React.ReactNode;
}

/**
 * Context for the Schema query, provides an object with `mutateFunction` (function which returns
 * a promise), `mutationData` (object) and `mutationLoading` (boolean) properties
 */
const StudyEntryMutationContext = React.createContext({});

/**
 * Creates/updates a StudyEntry. Provides data, status and mutation function via a context
 */
const StudyEntryMutation = (props: IStudyEntryMutationProps) => (
  <Mutation mutation={props.isExistingStudyEntry ? UPDATE_STUDY_ENTRY : CREATE_STUDY_ENTRY}>
    {(mutateFunction, { loading, error, data }) => (
      <React.Fragment>
        <StudyEntryMutationContext.Provider
          value={{
            mutateFunction,
            mutationData: data,
            mutationLoading: loading,
          }}
        >
          {props.children}
        </StudyEntryMutationContext.Provider>
        {error && (
          <ul>
            {error.graphQLErrors
              .filter(({ message }, i) => {
                try {
                  JSON.parse(message);
                  return false;
                } catch (e) {
                  // Not human readable
                  return true;
                }
              })
              .map(({ message }, i) => (
                <li key={i}>{message}</li>
              ))}
          </ul>
        )}
      </React.Fragment>
    )}
  </Mutation>
);

export { StudyEntryMutation, StudyEntryMutationContext };

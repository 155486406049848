import React from 'react';
import { useTranslation } from 'react-i18next';

export const ArrayFieldTemplate = props => {
  let collapse = true;
  const [t] = useTranslation();
  return (
    <div className={props.className}>
      <style>
        {`
            .card-header .fa {
              transition: .3s transform ease-in-out;
            }
            .card-header .collapsed .fa {
              transform: rotate(-90deg);
            }
            .description {
              font-size: 0.9rem;
              font-style: italic;
            }
         `}
      </style>
      {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].collapse && (
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="h6">
                  <button
                    className={'btn btn-link inline-block ' + (collapse ? 'collapsed' : 'show')}
                    type="button"
                    data-toggle="collapse"
                    href={'#collapse' + props.idSchema.$id}
                    aria-controls={'collapse' + props.idSchema.$id}
                    aria-expanded={collapse ? 'False' : 'True'}
                    id={'heading' + props.idSchema.$id}
                  >
                    <i className="fa fa-fw fa-chevron-down pull-left mr-1" />
                    <span>
                      {props.schema.title}
                      {props.required && '*'}
                    </span>
                  </button>
                </span>
              </div>
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              {props.canAdd && !props.disabled && (
                <div className="col-auto ml-auto">
                  <a
                    onClick={props.onAddClick}
                    className="btn btn-info btn-sm float-right"
                    role="button"
                  >
                    <i
                      className="fa fa-plus"
                      style={{
                        color: 'white',
                      }}
                    />
                  </a>
                </div>
              )}
              {/* eslint-enable jsx-a11y/anchor-is-valid */}
            </div>
          </div>
          <div
            id={'collapse' + props.idSchema.$id}
            className={collapse ? 'collapse' : 'collapse show'}
            aria-expanded={collapse ? 'False' : 'True'}
            aria-labelledby={'heading' + props.idSchema.$id}
          >
            <div className="card-body inline-block">
              <p className="description">{props.schema.description}</p>
              {props.items.map(element => (
                <div className="container">
                  <div className="row" key={element.index}>
                    <div className="col-sm-12">
                      <h6 className="card-title">
                        {'#'}
                        {element.index + 1}
                        {/* eslint-disable jsx-a11y/anchor-is-valid */}
                        {element.hasMoveDown && !props.disabled && (
                          <a
                            onClick={element.onReorderClick(element.index, element.index + 1)}
                            className="btn btn-info btn-sm mr-1 float-right"
                            role="button"
                          >
                            <i className="fas fa-arrow-down" style={{ color: 'white' }} />
                          </a>
                        )}
                        {element.hasMoveUp && !props.disabled && (
                          <a
                            onClick={element.onReorderClick(element.index, element.index - 1)}
                            className="btn btn-info btn-sm mr-1 float-right"
                            role="button"
                          >
                            <i className="fas fa-arrow-up" style={{ color: 'white' }} />
                          </a>
                        )}
                        {element.hasRemove && !props.disabled && (
                          <a
                            onClick={element.onDropIndexClick(element.index)}
                            className="btn btn-danger btn-sm mr-1 float-right"
                            role="button"
                          >
                            <i className="fas fa-minus" style={{ color: 'white' }} />
                          </a>
                        )}
                        {/* eslint-enable jsx-a11y/anchor-is-valid */}
                      </h6>
                      {element.children}
                    </div>
                  </div>
                </div>
              ))}
              {props.items.length === 0 && <p className="small text-center">{t('No entries')}</p>}
            </div>
          </div>
        </div>
      )}
      {props.items && (!props.uiSchema['ui:options'] || !props.uiSchema['ui:options'].collapse) && (
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center mb-0">
              <div className="col-auto">
                <span style={{ padding: '1rem 1rem' }}>
                  {props.schema.title}
                  {props.required && '*'}
                </span>
              </div>
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              {props.canAdd && !props.disabled && (
                <div className="col-auto ml-auto">
                  <a
                    onClick={props.onAddClick}
                    className="btn btn-info btn-sm float-right"
                    role="button"
                  >
                    <i
                      className="fa fa-plus"
                      style={{
                        color: 'white',
                      }}
                    />
                  </a>
                </div>
              )}
              {/* eslint-enable jsx-a11y/anchor-is-valid */}
            </div>
          </div>

          <div className="card-body inline-block">
            <p>{props.schema.description}</p>
            {props.items.map(element => (
              <div className="container">
                <div className="row" key={element.index}>
                  <div className="col-sm-12">
                    <h6 className="card-title">
                      {'#'}
                      {element.index + 1}
                      {/* eslint-disable jsx-a11y/anchor-is-valid */}
                      {element.hasMoveDown && !props.disabled && (
                        <a
                          onClick={element.onReorderClick(element.index, element.index + 1)}
                          className="btn btn-info btn-sm mr-1 float-right"
                          role="button"
                        >
                          <i className="fas fa-arrow-down" style={{ color: 'white' }} />
                        </a>
                      )}
                      {element.hasMoveUp && !props.disabled && (
                        <a
                          onClick={element.onReorderClick(element.index, element.index - 1)}
                          className="btn btn-info btn-sm mr-1 float-right"
                          role="button"
                        >
                          <i className="fas fa-arrow-up" style={{ color: 'white' }} />
                        </a>
                      )}
                      {element.hasRemove && !props.disabled && (
                        <a
                          onClick={element.onDropIndexClick(element.index)}
                          className="btn btn-danger btn-sm mr-1 float-right"
                          role="button"
                        >
                          <i className="fas fa-minus" style={{ color: 'white' }} />
                        </a>
                      )}
                      {/* eslint-enable jsx-a11y/anchor-is-valid */}
                    </h6>
                    {element.children}
                  </div>
                </div>
              </div>
            ))}
            {props.items.length === 0 && <p className="small text-center">{t('No entries')}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export const ObjectFieldTemplate = props => {
  let collapse = true;
  return (
    <div className={props.className}>
      <style>
        {`
          .description {
            font-size: 0.9rem;
            font-style: italic;
           }
         `}
      </style>
      {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].collapse && (
        <div className="card">
          <div className="card-header">
            <style>
              {`
                .card-header .fa {
                  transition: .3s transform ease-in-out;
                }
                .card-header .collapsed .fa {
                  transform: rotate(-90deg);
                }
              `}
            </style>
            <div className="row">
              <div className="col-sm-12">
                <h6 className="mb-0">
                  <button
                    className={'btn btn-link d-block ' + (collapse ? 'collapsed' : 'show')}
                    type="button"
                    data-toggle="collapse"
                    href={'#collapse' + props.idSchema.$id}
                    aria-controls={'collapse' + props.idSchema.$id}
                    aria-expanded={collapse ? 'False' : 'True'}
                    id={'heading' + props.idSchema.$id}
                  >
                    <i className="fa fa-fw fa-chevron-down pull-left mr-1" />
                    {props.title && (
                      <span>
                        {props.title} {props.required && '*'}
                      </span>
                    )}
                  </button>
                </h6>
              </div>
            </div>
          </div>
          <div
            id={'collapse' + props.idSchema.$id}
            className={collapse ? 'collapse' : 'collapse show'}
            aria-expanded={collapse ? 'False' : 'True'}
            aria-labelledby={'heading' + props.idSchema.$id}
          >
            <div className="card-body">
              <p className="description">{props.description}</p>
              <div
                className={
                  props.uiSchema['ui:options'] && props.uiSchema['ui:options'].inline
                    ? 'row ml-10'
                    : 'ml-10'
                }
              >
                {props.properties.map(prop => (
                  <div
                    className={
                      prop.content.props.schema.type === 'array' ||
                      (prop.content.props.uiSchema['ui:options'] &&
                        prop.content.props.uiSchema['ui:options'].collapse)
                        ? 'w-100'
                        : undefined
                    }
                    key={prop.content.key}
                  >
                    {prop.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {(!props.uiSchema['ui:options'] || !props.uiSchema['ui:options'].collapse) && (
        <div>
          <h6>
            {props.title} {props.required}
          </h6>
          <style>
            {`
                .row {
                  margin-right: inherit
                }
                .form-group {
                  margin-right: 0.5rem;
                  margin-left: 0.5rem;
                }
              `}
          </style>
          <p className="description">{props.description}</p>
          <div
            className={
              props.uiSchema['ui:options'] && props.uiSchema['ui:options'].inline
                ? 'row ml-10'
                : 'ml-10'
            }
          >
            {props.properties.map(prop => (
              <div
                className={
                  prop.content.props.schema.type === 'array' ||
                  (prop.content.props.uiSchema['ui:options'] &&
                    prop.content.props.uiSchema['ui:options'].collapse)
                    ? 'w-100'
                    : undefined
                }
                key={prop.content.key}
              >
                {prop.content}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import Query from 'react-apollo/Query';
import { useTranslation } from 'react-i18next';
import { GET_STUDY_ENTRY, GET_STUDY_ENTRY_REVISION } from '../../graphql/StudyEntry';
import LoadingSpinner from './LoadingSpinner';

interface ISchemaQueryProps {
  /**
   * ID of object to be retrieved. Takes precedence over `revisionId`
   */
  objectId: string;
  /**
   * ID of revision to be retrieved. Overriden by `objectId`
   */
  revisionId: string;
  /**
   * If true, the query is not executed
   * @default false
   */
  skip: boolean;
  /**
   * This component's children. See `StudyEntryQueryContext` to see the provided context
   */
  children: React.ReactNode;
}

/**
 * Context for the Schema query, provides an object with `loading` (boolean), `data`
 * (object) and `error` (object) properties
 */
const StudyEntryQueryContext = React.createContext({});

/**
 * Retrieves a study entry or revision and provides it via a React context
 */
const StudyEntryQuery = (props: ISchemaQueryProps) => {
  const [t] = useTranslation();
  return (
    <Query
      query={props.objectId ? GET_STUDY_ENTRY : GET_STUDY_ENTRY_REVISION}
      variables={{
        id: props.objectId || props.revisionId,
      }}
      fetchPolicy={'cache-and-network'}
      skip={props.skip}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingSpinner />;
        }

        if (error) {
          return (
            <div className="alert alert-danger" role="alert">
              {t("Error: Couldn't retrieve entry")}
            </div>
          );
        }

        if (!props.skip) {
          if (props.objectId) {
            data = data.studyEntry;
          } else {
            data = data.studyEntryRevision;
          }
        }

        return (
          <StudyEntryQueryContext.Provider value={data}>
            {props.children}
          </StudyEntryQueryContext.Provider>
        );
      }}
    </Query>
  );
};

export { StudyEntryQuery, StudyEntryQueryContext };

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import StudyEntryForm from './components/StudyEntryForm';
import { CSVImporter } from './components/CSVImporter';

// Needs to be bundled
import './i18n';

// export here components to use in the Django app
export { StudyEntryForm, CSVImporter };

/* tslint:disable */
require('expose-loader?Components!./index.ts');
require('expose-loader?React!react');
require('expose-loader?ReactDOM!react-dom');
/* tslint:enable */

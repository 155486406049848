import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';

import 'react-widgets/dist/css/react-widgets.css';

export interface IWidgetOptions {
  /**
   * Array of available views.
   * @example ["month", "year", "decade", "century"]
   * @default ["year", "decade", "century"]
   */
  views: Array<string>;
  /**
   * Picked date is displayed in this format, eg. "MMM YYYY".
   * @default "MMM YYYY"
   */
  format: string;
  /**
   * Max. date that can be selected in the UI (CAVEAT: No validation against manual entries!).
   * Format has to be ISO 8601 or "today".
   * @default "today"
   */
  max: string;
}

export interface IDatePickerWidgetProps {
  /**
   * Value for the field. Useful for initialization.
   */
  value: string | undefined;
  /**
   * Determines how the widget parses the typed date string into a Date object.
   */
  parse: Array<string>;
  /**
   * If false, the footer is disabled.
   * @default false
   */
  footer: boolean;
  /**
   * If true, the field is disabled.
   * @default false
   */
  disabled: boolean;
  /**
   * If true, the field is readonly.
   * @default false
   */
  readonly: boolean;
  /**
   * A callback fired when the current value changes.
   */
  onChange: Function;
  /**
   * Options passed via `ui:options` in the UI Schema for this field.
   */
  options: IWidgetOptions;
}

/**
 *  Enables a date-picker for RJSF. Use as follows:
 *  @example <caption>Add something like this to your UISchema.</caption>
 * ```
 * "date": {
 *   "ui:widget": "datePickerWidget",
 *   "ui:options": {
 *     "views": ["year", "decade", "century"],
 *     "format": "MMM YYYY",
 *     "max": "today"
 *   }
 * }
 * ```
 */
export const DatePickerWidget = (
  props: IDatePickerWidgetProps,
  defaultProps: Partial<IDatePickerWidgetProps>
) => {
  const [t] = useTranslation();

  function maxDate(max: string): any {
    return max === 'today' ? new Date() : new Date(max);
  }

  return (
    <DateTimePicker
      value={!props.value ? undefined : new Date(props.value)}
      time={false}
      format={props.options.format}
      max={maxDate(props.options.max)}
      parse={props.parse}
      footer={false}
      disabled={props.disabled}
      readonly={props.readonly}
      views={props.options.views}
      onChange={(v: any) => {
        v && props.onChange(Moment(v).format('YYYY-MM-DD'));
      }}
    />
  );
};

DatePickerWidget.defaultProps = {
  options: {
    views: ['year', 'decade', 'century'],
    format: 'MMM YYYY',
    max: 'today',
  },
};

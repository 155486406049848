import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusIndicator, IStatusIndicatorProps } from './StatusIndicator';

export interface ISubmitButtonProps extends IStatusIndicatorProps {
  /**
   * Disables the button if true.
   * @default false
   **/
  disabled?: boolean;
  /**
   * Shows a StatusIndicator side by side if true.
   * @default false
   **/
  showStatusIndicator?: boolean;
  /**
   * Optional function to be called on click
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Displays a form submission button, alongside an optional `StatusIndicator`
 */
export const SubmitButton = (props: ISubmitButtonProps) => {
  const [t] = useTranslation();

  return (
    <div className="container">
      <div className="row align-items-center">
        <button
          onClick={props.onClick}
          type="submit"
          className="btn btn-primary mr-2"
          disabled={props.disabled}
        >
          <span className="fas fa-save fa-fw" /> {t('Save')}
        </button>{' '}
        {props.showStatusIndicator && <StatusIndicator status={props.status} />}
      </div>
    </div>
  );
};

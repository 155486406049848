import { RJSFTypeaheadWidget } from './RJSFTypeaheadWidget';
import { DatePickerWidget } from './DatePickerWidget';
import { ImageWidget } from './ImageWidget';
import { AnnotatedUpDownWidget } from './AnnotatedUpDownWidget';

/**
 * All custom widgets should be defined in this object, which is passed to
 * `StudyEntryForm`.
 */
export default {
  typeaheadWidget: RJSFTypeaheadWidget,
  datePickerWidget: DatePickerWidget,
  imageWidget: ImageWidget,
  annotatedUpDown: AnnotatedUpDownWidget,
};

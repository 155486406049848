import * as React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export interface IImageWidgetOptions {
  /**
   * Height of image preview (will keep aspect ratio if not used together with width). Original image
   * dimension used if not specified.
   */
  height?: string;
  /**
   * Width of image preview (will keep aspect ratio if not used together with height). Original image
   * dimension used if not specified.
   */
  width?: string;
}

export interface IImageWidgetProps {
  /**
   * Value for the field. Useful for initialization.
   */
  value: string | undefined;
  /**
   * If true, the field is disabled.
   * @default false
   */
  disabled: boolean;
  /**
   * If true, the field is readonly.
   * @default false
   */
  readonly: boolean;
  /**
   * A callback fired when the current value changes.
   */
  onChange: Function;
  /**
   * Options passed via `ui:options` in the UI Schema for this field.
   */
  options: IImageWidgetOptions;
}

const defaultProps: Partial<IImageWidgetProps> = {
  options: {
    height: "200",
  },
};

const readFileAsDataURL = (file: Blob, callback: Function) => {
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    function () {
      callback(reader.result);
    },
    false
  );
  reader.readAsDataURL(file);
};

const handleFileImageChoice = (event: any, callback: Function) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    readFileAsDataURL(file, callback);
    event.target.value = null;
  }
};

function handlePasteImageChoice(event: any, callback: Function) {
  if (!event.clipboardData || !event.clipboardData.items || !event.clipboardData.items[0]) {
    return;
  }

  const item = event.clipboardData.items[0];
  if (item.type.indexOf("image") == -1) return;
  const file = item.getAsFile();
  readFileAsDataURL(file, callback);
  event.target.value = null;
}

/**
 *  Enables RJSF to store and display images as form elements.
 */
export const ImageWidget: React.FunctionComponent<IImageWidgetProps> = (props) => {
  const [t] = useTranslation();
  const filePickerInput = useRef<HTMLInputElement>(null);
  const { disabled, readonly, value, onChange, options } = props;

  return (
    <div>
      {!disabled && !readonly && (
        <div className="input-group">
          <div className="input-group-prepend">
            {value && (
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => onChange(undefined)}
              >
                <i className="fas fa-trash" />
              </button>
            )}
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => filePickerInput.current && filePickerInput.current.click()}
            >
              <i className="fas fa-file"></i>
              <span> </span>
              {value ? t("Replace image...") : t("Upload image...")}
            </button>
          </div>
          <input
            type="text"
            className="form-control"
            onPaste={(event: any) => handlePasteImageChoice(event, onChange)}
            onChange={(event: any) => (event.target.value = null)}
            placeholder={t("Or paste it here")}
          />
          <input
            ref={filePickerInput}
            style={{ position: "absolute", zIndex: -1, opacity: 0 }}
            type="file"
            accept="image/png,image/x-png,image/gif,image/jpeg"
            onChange={(event: any) => handleFileImageChoice(event, onChange)}
          />
        </div>
      )}
      <div>
        {value ? (
          <img className="mt-1" height={options.height} width={options.width} src={value} />
        ) : (
          <span className="small">{t("No image selected")}</span>
        )}
      </div>
    </div>
  );
};

ImageWidget.defaultProps = defaultProps;

import gql from 'graphql-tag';

export const CREATE_STUDY_ENTRY = gql`
  mutation CreateStudyEntry($data: StudyEntryCreateInput!) {
    createStudyEntry(studyEntryData: $data) {
      newStudyEntry {
        id
        data
        url
      }
    }
  }
`;

export const UPDATE_STUDY_ENTRY = gql`
  mutation UpdateStudyEntry($id: String!, $data: StudyEntryCreateInput!) {
    updateStudyEntry(id: $id, studyEntryData: $data) {
      updatedStudyEntry {
        id
        data
      }
    }
  }
`;

export const GET_STUDY_ENTRY = gql`
  query StudyEntry($id: String!) {
    studyEntry(id: $id) {
      id
      data
    }
  }
`;

export const GET_STUDY_ENTRY_REVISION = gql`
  query StudyEntry($id: String!) {
    studyEntryRevision(id: $id) {
      id
      data
    }
  }
`;

export const GET_SCHEMA = gql`
  query StudyEntry {
    schema {
      schema
      uiSchema
    }
  }
`;

export const TYPEAHEAD = gql`
  query StudyEntry($field: String!) {
    typeahead(field: $field)
  }
`;

/* tslint:disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form-bs4';
import { setState } from 'react-jsonschema-form-bs4/lib/utils';
import { withApollo } from '../utils';
import { useTranslation, withTranslation } from 'react-i18next';
import localizeErrors from './rjsf/utils/localizeErrors';
import { SubmitButton } from './common/SubmitButton';
import { StatusIndicator, Status } from './common/StatusIndicator';
import LoadingSpinner from './common/LoadingSpinner';
import applyNavs from 'react-jsonschema-form-pagination';
import { GENERIC_NAV } from 'react-jsonschema-form-pagination/lib/utils';
import config from '../config';
import { ArrayFieldTemplate, ObjectFieldTemplate } from './rjsf/customComponents';
import { SchemaQuery, SchemaQueryContext } from './common/SchemaQuery';
import CustomWidgets from './rjsf/CustomWidgets';
import CustomFields from './rjsf/CustomFields';
import { StudyEntryQuery, StudyEntryQueryContext } from './common/StudyEntryQuery';
import { StudyEntryMutation, StudyEntryMutationContext } from './common/StudyEntryMutation';
import setWith from 'lodash/setWith';

/**
 * Overrides RJSF's `Form.onSubmit()` to have server side validation. Assumes that the
 * value passed as the `onSubmit` prop is a function returning a promise. Further parses
 * backend errors into a format compatible with RJSF.
 */
class FormWithServerSideValidation extends Form {
  onSubmit = event => {
    event.preventDefault();

    // Client-side validation
    if (!this.props.noValidate) {
      const { errors, errorSchema } = this.validate(this.state.formData);
      if (Object.keys(errors).length > 0) {
        setState(this, { errors, errorSchema }, () => {
          if (this.props.onError) {
            this.props.onError(errors);
          } else {
            console.error('Form validation failed', errors);
          }
        });
        return;
      }
    }

    // Server-side validation
    this.setState({ errors: [], errorSchema: {} }, () => {
      if (this.props.onSubmit) {
        this.props
          .onSubmit({ ...this.state, status: 'submitted' })
          .then(data => {
            if (typeof this.props.onSuccess === 'function') {
              this.props.onSuccess(data);
            }
          })
          .catch(error => {
            let errorObj = {};
            try {
              errorObj = JSON.parse(error.graphQLErrors[0].message);
            } catch (error) {
              // Not a JSON response, not for us...
              return;
            }
            const dataErrorObj = errorObj.data ? JSON.parse(errorObj.data) : {};
            delete errorObj.data;

            let errorSchema = {};
            let errors = [];

            for (let property in Object.assign(errorObj, dataErrorObj)) {
              if (errorObj.hasOwnProperty(property)) {
                setWith(
                  errorSchema,
                  property,
                  {
                    __errors: errorObj[property],
                  },
                  Object
                );

                for (let error in errorObj[property]) {
                  errors.push({
                    message: errorObj[property][error],
                    property: '.' + property,
                    stack: '.' + property + ': ' + errorObj[property][error],
                  });
                }
              }
            }

            if (this.props.onError) {
              this.props.onError(errors);
            } else {
              console.error('Form validation failed', errors);
            }

            this.setState({
              errorSchema,
              errors,
            });
          });
      }
    });
  };
}

/**
 * Replaces the navs provided by the `react-jsonschema-form-pagination` package
 * with BS4 compatible ones.
 */
const CustomNavs = ({ navs: { links }, onNavChange }) => {
  let relLinks = links.filter(({ nav }) => nav !== GENERIC_NAV);
  return (
    <ul className="nav nav-pills">
      {relLinks.map(({ nav, name, icon, isActive }, i) => (
        <li key={nav} className="nav-item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              onNavChange(nav);
              return false;
            }}
            className={isActive ? 'nav-link active' : 'nav-link'}
            style={{ padding: '0.5rem 0.5rem' }}
          >
            {icon && <span className={icon} aria-hidden="true" />}
            &nbsp;{name || nav}
          </a>
        </li>
      ))}
    </ul>
  );
};

// Conditionally apply navs depending on current config
let FormWithSSVAndNavs;
if (config.useNavs) {
  FormWithSSVAndNavs = applyNavs(FormWithServerSideValidation, CustomNavs);
} else {
  FormWithSSVAndNavs = FormWithServerSideValidation;
}

/**
 * Custom error list for RJSF's `Form`, adds localization
 */
const ErrorList = props => {
  const { errors } = props;
  const [t] = useTranslation();
  return (
    <div className="card errors">
      <div className="card-header text-white bg-danger">{t('Errors')}</div>
      <ul className="list-group">
        {errors.map((error, i) => {
          return (
            <li key={i} className="list-group-item text-danger">
              {error.stack}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

class StudyEntryFormUnwrapped extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: this.shouldLoadStudyEntry(),
      errors: [],
      isInitialLoad: this.shouldLoadStudyEntry(),
    };
  }

  handleChange = ({ formData }) => {
    this.setState(
      prevState => ({
        formData,
        saved: prevState.isInitialLoad,
        isInitialLoad: false,
      }),
      () => {
        this.state.saved
          ? window.removeEventListener('beforeunload', this.handleWindowBeforeUnload)
          : window.addEventListener('beforeunload', this.handleWindowBeforeUnload);
      }
    );
  };

  handleSubmit = (mutateFunction, formData) => {
    return mutateFunction({
      variables: {
        id: this.props.objectId,
        data: {
          data: JSON.stringify(formData),
        },
      },
    });
  };

  handleSuccess = data => {
    this.setState({ saved: true, errors: [] }, () => {
      window.removeEventListener('beforeunload', this.handleWindowBeforeUnload);
      if (!this.props.hasOwnProperty('objectId')) {
        window.location = data.data.createStudyEntry.newStudyEntry.url;
      }
    });
  };

  shouldLoadStudyEntry = () =>
    this.props.hasOwnProperty('objectId') || this.props.hasOwnProperty('revisionId');

  getStatus = loading => {
    if (loading) {
      return Status.Loading;
    }

    if (this.state.saved) {
      return Status.Saved;
    }

    if (this.state.errors.length > 0) {
      return Status.ValidationFailed;
    }

    return Status.UnsavedChanges;
  };

  handleWindowBeforeUnload = e => {
    e.preventDefault();
    e.returnValue = '';
  };

  render() {
    return (
      <SchemaQuery>
        <SchemaQueryContext.Consumer>
          {({ schema, uiSchema }) => (
            <StudyEntryQuery
              objectId={this.props.objectId}
              revisionId={this.props.revisionId}
              skip={!this.shouldLoadStudyEntry()}
            >
              <StudyEntryQueryContext.Consumer>
                {objectData => (
                  <StudyEntryMutation
                    skip={this.props.disabled}
                    isExistingStudyEntry={this.shouldLoadStudyEntry()}
                  >
                    <StudyEntryMutationContext.Consumer>
                      {({ mutateFunction, mutationLoading, mutationData }) => (
                        <React.Fragment>
                          <FormWithSSVAndNavs
                            widgets={CustomWidgets}
                            fields={CustomFields}
                            ErrorList={ErrorList}
                            disabled={this.props.disabled || mutationLoading}
                            schema={schema}
                            uiSchema={uiSchema}
                            ArrayFieldTemplate={ArrayFieldTemplate}
                            ObjectFieldTemplate={ObjectFieldTemplate}
                            transformErrors={localizeErrors}
                            onChange={this.handleChange}
                            formData={
                              this.state.formData ||
                              (this.shouldLoadStudyEntry() ? JSON.parse(objectData.data) : {})
                            }
                            onSubmit={({ formData }) => {
                              return this.handleSubmit(mutateFunction, formData);
                            }}
                            onSuccess={this.handleSuccess}
                            onError={errors => this.setState({ errors })}
                            autocomplete="off"
                          >
                            {this.props.disabled ? (
                              <React.Fragment />
                            ) : (
                              <SubmitButton
                                showStatusIndicator={!config.useNavs}
                                disabled={
                                  mutationLoading ||
                                  (!this.props.hasOwnProperty('objectId') && mutationData)
                                }
                                status={this.getStatus(mutationLoading)}
                              />
                            )}
                          </FormWithSSVAndNavs>
                          {this.props.disabled ||
                            (config.useNavs && (
                              <div className="mt-3">
                                <StatusIndicator status={this.getStatus(mutationLoading)} />
                              </div>
                            ))}
                        </React.Fragment>
                      )}
                    </StudyEntryMutationContext.Consumer>
                  </StudyEntryMutation>
                )}
              </StudyEntryQueryContext.Consumer>
            </StudyEntryQuery>
          )}
        </SchemaQueryContext.Consumer>
      </SchemaQuery>
    );
  }
}

StudyEntryFormUnwrapped.propTypes = {
  /**
   * Disables the form. Useful for displaying static data.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * ID of object to retrieve. Overrides `revisionId`.
   */
  objectId: PropTypes.number,
  /**
   * ID of revision to retrieve. Incompatible with `objectId`.
   */
  revisionId: PropTypes.number,
};

const StudyEntryFormWrapped = withTranslation()(withApollo(StudyEntryFormUnwrapped));

/**
 * Form for editing StudyEntries. Contains several improvements over standard RJSF
 * such as pagination, localization, server side validation, BS4 compatibility, etc.
 */
const StudyEntryForm = props => {
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <StudyEntryFormWrapped {...props} />
    </React.Suspense>
  );
};

export default StudyEntryForm;

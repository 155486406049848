import * as React from 'react';
import Query from 'react-apollo/Query';
import { TYPEAHEAD } from '../../graphql/StudyEntry';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../common/LoadingSpinner';

import 'react-bootstrap-typeahead/css/Typeahead.css';

export interface IWidgetOptions {
  /**
   * Path to the field. Should be something like "prop1.prop2.prop3".
   * In this example, if `prop2` were an object array, the typeahead would
   * include `prop3` values from all objects in that array.
   */
  fieldPath: string;
}

export interface IRJSFTypeaheadWidgetProps {
  /**
   * Value for the field. Useful for initialization.
   */
  value: string;
  /**
   * If true, the field is disabled
   * @default false
   */
  disabled: boolean;
  /**
   * If true, the user may not edit this field
   * @default false
   */
  readonly: boolean;
  /**
   * Called every time the field's value changes
   */
  onChange: Function;
  /**
   * Options passed via `ui:options` in the UI Schema for this field
   */
  options: IWidgetOptions;
}

/**
 * Enables GraphQL based typeahead for RJSF. Use as follows:
 * @example <caption>Add something like this to your UISchema.</caption>
 *
 * ```
 * "notes": {
 *   "ui:widget": "typeaheadWidget",
 *   "ui:options": {"fieldPath": "notes"}
 * }
 * ```
 */
export const RJSFTypeaheadWidget = (props: IRJSFTypeaheadWidgetProps) => {
  const [t] = useTranslation();
  return (
    <Query
      query={TYPEAHEAD}
      variables={{
        field: props.options.fieldPath,
      }}
      fetchPolicy={'cache-and-network'}
    >
      {({ loading, data }) => {
        if (loading) {
          return <LoadingSpinner />;
        }

        return (
          <Typeahead
            newSelectionPrefix={t('New value: ')}
            defaultSelected={props.value ? [props.value] : undefined}
            ignoreDiacritics={true}
            onInputChange={(text: any) => {
              if (text.length === 0) {
                props.onChange(undefined);
              } else {
                props.onChange(text);
              }
            }}
            onChange={(options: any) => {
              if (options.length === 1) {
                if (options[0].label) {
                  props.onChange(options[0].label);
                } else {
                  props.onChange(options[0]);
                }
              }
            }}
            disabled={props.disabled || props.readonly}
            allowNew={true}
            minLength={0}
            options={data && data.typeahead ? data.typeahead : []}
            emptyLabel={''}
            multiple={false}
          />
        );
      }}
    </Query>
  );
};

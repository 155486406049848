import * as React from 'react';
import { FieldProps } from 'react-jsonschema-form-bs4';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface IImageFieldOptions {
  /**
   * Image URL
   * @note Use `django.contrib.staticfiles.templatetags.staticfiles.static()` to
   *       resolve static URLs as in https://stackoverflow.com/a/17738606/280698
   */
  image?: string;
  /**
   * Alt-text to be displayed in case image cannot be loaded. Generic message
   * displayed if not specified.
   */
  altText?: string;
  /**
   * Image height for resizing (will keep aspect ratio if not used together with width). Original image
   * dimension used if not specified.
   */
  height?: string;
  /**
   * Image width for resizing (will keep aspect ratio if not used together with height). Original image
   * dimension used if not specified.
   */
  width?: string;
  /**
   * Image and title alignment.
   * @param align  Align can be left, center or right. Default is left.
   */
  align?: string;
}

export interface IImageFieldProps extends WithTranslation, FieldProps {
  uiSchema: {
    'ui:options': IImageFieldOptions;
  };
}

export class ImageFieldUnwrapped extends React.Component<IImageFieldProps> {
  constructor(props: any) {
    super(props);
  }

  getUiOption = (option: keyof IImageFieldOptions) => {
    return this.props.uiSchema['ui:options']
      ? this.props.uiSchema['ui:options'][option]
      : undefined;
  };

  getAlignment = (option: keyof IImageFieldOptions) => {
    let prefix: string = 'text-';
    if (
      this.props.uiSchema['ui:options'] &&
      (this.props.uiSchema['ui:options'][option] == 'left' ||
        this.props.uiSchema['ui:options'][option] == 'center' ||
        this.props.uiSchema['ui:options'][option] == 'right')
    ) {
      return (prefix += this.props.uiSchema['ui:options'][option]);
    } else {
      return 'text-left';
    }
  };

  render = () => (
    <div className={this.getAlignment('align')}>
      {this.props.schema.title && <p>{this.props.schema.title}</p>}
      <img
        src={this.getUiOption('image')}
        alt={
          this.getUiOption('altText') ||
          this.props.t('An image should be here that cannot be displayed.')
        }
        height={this.getUiOption('height')}
        width={this.getUiOption('width')}
      />
    </div>
  );
}

export const ImageField = withTranslation()(ImageFieldUnwrapped);
